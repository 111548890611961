import { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import axios from './customAxios';
import { useLogin } from './LoginContext';

// Create the context
const SubscriptionStatusContext = createContext(null);

// Custom hook to use the context
export const useSubscriptionStatus = () => {
  return useContext(SubscriptionStatusContext);
};

// Provider component
export const SubscriptionStatusProvider = ({ children }) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isSubscriptionCachedValid, setIsSubscriptionCachedValid] = useState(false); // Cached validity result

  const [loading, setLoading] = useState(false);
  const { setState, buyer, serviceSubscription } = useLogin();
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState(buyer.subscriptionEndDate);
  const [rememberMeToken, setRememberMeToken] = useState(null); 
 // console.log(subscriptionStatus, isSubscriptionCachedValid, currentPeriodEnd);
  useEffect(() => {
    setCurrentPeriodEnd(buyer.subscriptionEndDate);
  }, [buyer]);
  // useEffect(() => {
  //   // Check if a remember me token exists in local storage
  //   const storedToken = localStorage.getItem('rememberMeToken');
  //   if (storedToken) {
  //     setRememberMeToken(storedToken);
  //   }
  // }, []);
  async function compareStatus() {
    const nowUtc = Date.now();
    const valid = nowUtc < currentPeriodEnd;
  //  console.log(valid, nowUtc, currentPeriodEnd);
    // in the case that the user is logged in but the subscription date is still the old cycle
    // but they renewed to the new cycle. We need to update the subscription status
    if (!valid) {
      const { data } = await axios.get('/subscription/check-subscription-status');
   //   console.log(data);
      setSubscriptionStatus(data?.subscriptionStatus);
      if (currentPeriodEnd !== data.subscriptionEndDate) {
        setCurrentPeriodEnd(data?.subscriptionEndDate); // Convert to a Date object
        // current buyer needs an update
      }
      const valid2 = nowUtc < data?.subscriptionEndDate;
     // console.log(valid2);
      setIsSubscriptionCachedValid(valid2);
      setState((prevInputs) => ({
        ...prevInputs, serviceSubscription: valid2,
      }));
      localStorage.setItem('serviceSubscription', JSON.stringify(valid2));
      localStorage.setItem('currentPeriodEnd', JSON.stringify(data.subscriptionEndDate));

    } else {
      localStorage.setItem('serviceSubscription', JSON.stringify(true));
      localStorage.setItem('currentPeriodEnd', JSON.stringify(currentPeriodEnd));
      setSubscriptionStatus(true);
      setIsSubscriptionCachedValid(valid);
      setState((prevInputs) => ({
        ...prevInputs, serviceSubscription: valid,
      }));
     // console.log('set sub as valid!')
    }
  }
  // Function to fetch and update the subscription status
  const fetchSubscriptionStatus = useCallback(async () => {
 // console.log('fetchSubscriptionStatus called');
    setLoading(true); // Start loading
 //   console.log(buyer.subscriptionStatus);
    try {
      // get the current subscription info from buyer
      // check status if empty
      const storedSubscriptionStatus = localStorage.getItem('serviceSubscription');
      const storedCurrentPeriodEnd = localStorage.getItem('currentPeriodEnd');
      // WIP using localStorage
// if (storedSubscriptionStatus && storedCurrentPeriodEnd) {
//       const subscriptionStatus = JSON.parse(storedSubscriptionStatus);
//       const currentPeriodEnd = JSON.parse(storedCurrentPeriodEnd);
//       setSubscriptionStatus(subscriptionStatus);
//       setCurrentPeriodEnd(currentPeriodEnd);
//       setIsSubscriptionCachedValid(nowUtc < currentPeriodEnd);
      
      if  (!buyer.subscriptionStatus) {
        setSubscriptionStatus(false);
        setIsSubscriptionCachedValid(false);
      } else {
        await compareStatus();
      }
      return true
    } catch (error) {
      // console.error('Error fetching subscription status:', error);
      setSubscriptionStatus(null);
      setCurrentPeriodEnd(null);
      setState((prevInputs) => ({
        ...prevInputs, serviceSubscription: false,
      }))
      setIsSubscriptionCachedValid(false);
      return true
    } finally {
      setLoading(false); // Finish loading
   
    }

  }, [buyer]);
  // Fetch the subscription status when the component mounts
  // useEffect(() => {
  //  (buyer && token) && fetchSubscriptionStatus();
  //   //localStorage.getItem('isSubscriptionValid');

  // }, [buyer]);

  const isSubscriptionValid = () => {
    // console.log(isSubscriptionCachedValid);
    // const nowUtc = Date.now();
    // const valid = nowUtc < currentPeriodEnd;
    return isSubscriptionCachedValid; // Return the cached validity state
  };

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    isSubscriptionCachedValid,
    subscriptionStatus,
    isSubscriptionValid,
    fetchSubscriptionStatus,

    loading

  }), [isSubscriptionCachedValid, isSubscriptionValid, subscriptionStatus, currentPeriodEnd, loading]);

  return (
    <SubscriptionStatusContext.Provider value={contextValue}>
      {children}
    </SubscriptionStatusContext.Provider>
  );
};
// export const useSubscriptionStatus = () => {
//   return useContext(SubscriptionStatusContext);
// };